import { Notyf } from "notyf"

window.logEvent = (event, path = window.location.href) => {
  const e = {
    error: event,
    path: path,
    user: document.querySelector('body')?.dataset?.userEmail
  }
  fetch(`${window.location.origin}/js-log`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      authenticity_token: window._token,
      ...e
    })
  })

  // recordAhoy.now({ event: 'js_error', event })
}

window.onerror = logEvent

document.addEventListener('turbo:frame-missing', (e) => {
  e.preventDefault()

  // const notyf = new Notyf({ position: { x: 'center', y: 'top' } })
  // notyf.error("Oops, there was an error. Can you try again?")

  const message = e.target.id !== undefined ? `turbo:frame-missing #${e.target.id}` : `turbo:frame-missing (no id on turbo_frame)`
  logEvent(message, e.target?.response?.url)
})
